import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { REPORT_API_REDUCER_KEY } from '../consts';
import { IReport, IReportFilters, IReportJson } from './types';

export const reportApi = createApi({
    reducerPath: REPORT_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Report', 'ReportJson'],
    endpoints: (builder) => ({
        //* fetch all reports with filters

        fetchReports: builder.query<IReport, Partial<IReportFilters>>({
            query: (params: any) => ({
                url: '/report',
                method: 'GET',
                params: params,
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // *fetch JSON report data

        fetchReportJsonData: builder.query<IReport, Partial<IReportJson>>({
            query: (params: { id: any; key: any; }) => ({
                url: `/report/json-data/${params.id}`,
                method: 'GET',
                params: { json: params.key },
                providesTags: ['ReportJson'],
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),


        // *fetch NATS console log for video link search

        fetchReportVideoData: builder.query<IReport, Partial<IReportJson>>({
            query: (params: { id: any }) => ({
                url: `/report/mobile-data/${params.id}`,
                method: 'GET',
                params: { type: "video_url" }
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),
        

        // * fetch exported reports

        fetchExportedReports: builder.query<IReport, Partial<IReportFilters>>({
            query: (params: any) => ({
                url: '/report/getReportsByRun',
                method: 'GET',
                params: params,
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * download reports

        downloadReport: builder.query<IReport, Partial<IReportJson>>({
            query: (params: { id: any; }) => ({
                url: `/report/download/${params.id}/`,
                responseType: 'arraybuffer',
                method: 'GET',
                params: { isBlob: true },
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * fetch Report Permanent Status

        fetchReportPermanentStatus: builder.mutation<IReport, Partial<IReportFilters>>({
            query: (params: any) => ({
                url: '/report/set-storage-state',
                method: 'POST',
                // providesTags: ['Report'],
                data: params,
            }),
            invalidatesTags: [{ type: 'Report', id: 'LIST' }],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * download Html Report

        downloadReportByRunId: builder.query<IReport, Partial<IReportFilters>>({
            query: (params: any) => ({
                url: '/report/getHtmlReport/dashboard',
                method: 'GET',
                responseType: 'arraybuffer',
                params: params,
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

    }),
});


export const {
    useFetchReportsQuery,
    useFetchReportPermanentStatusMutation,
} = reportApi;
